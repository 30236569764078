import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Settings } from 'luxon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        // luxon paramètres
        Settings.defaultLocale = 'fr'
        Settings.defaultZone = 'Europe/Paris'

        this.matIconRegistry.addSvgIcon("sorius_check",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/sorius/icons/check.svg")
        );
        this.matIconRegistry.addSvgIcon("sorius_cross",
            this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/sorius/icons/red_circle.svg")
        );
    }




}
