import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminDataResolver } from './admin.resolvers';
import { BusinessDataResolver } from './business.resolvers';
import { AdminGuard } from './core/auth/guards/admin.guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [


    // Redirect empty path to '/dashboard'
    // redirige vers /home si pas de droit admin
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },


    // Redirect signed in user to the '/example'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },

    // Auth routes for business
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: BusinessDataResolver,
        },
        data: {
            layout: 'classic'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/business-partner/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'tracking', loadChildren: () => import('app/modules/business-partner/tracking/tracking.module').then(m => m.TrackingModule) },
            { path: 'documents', loadChildren: () => import('app/modules/business-partner/documents/documents.module').then(m => m.DocumentsModule) }
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AdminGuard],
        canActivateChild: [AdminGuard],
        component: LayoutComponent,
        resolve: {
            initialData: AdminDataResolver,
        },
        data: {
            layout: 'classic'
        },
        children: [
            { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'challenges', loadChildren: () => import('app/modules/admin/challenges/challenges.module').then(m => m.ChallengesModule) },
            { path: 'stats', loadChildren: () => import('app/modules/admin/stats/stats.module').then(m => m.StatsModule) },
            { path: 'users', loadChildren: () => import('app/modules/admin/users/user.module').then(m => m.UserModule) },
            { path: 'coaching', loadChildren: () => import('app/modules/admin/coaching/coaching.module').then(m => m.CoachingModule) }
        ]
    }
];
