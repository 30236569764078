import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { AppConstants } from "app/app.constants";
import * as _ from "lodash";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserService {
    private url = AppConstants.PRIVATE_URL + 'admin/user';
    private urlNotAdmin = AppConstants.PRIVATE_URL + 'user';
    private public = AppConstants.PUBLIC_URL + 'unsecured';

    private refreshUserObservableSource = new Subject<any>()

    refreshUserObservable$ = this.refreshUserObservableSource.asObservable()

    private refreshCalendarObservableSource = new Subject<any>()

    refreshCalendarObservable$ = this.refreshCalendarObservableSource.asObservable()

    constructor(
        private http: HttpClient
    ) { }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        if (!token) {
            localStorage.removeItem('accessToken')
        } else {
            localStorage.setItem('accessToken', token);
        }
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    isConnected() {
        return this.http.get(this.public + '/connected', AppConstants.HTTP_OPTIONS);
    }

    logout() {
        this.accessToken = ''
    }

    login(dto) {
        return this.http.post(this.public + '/login', dto, AppConstants.HTTP_OPTIONS);
    }

    current() {
        return this.http.get(this.urlNotAdmin + '/admin', AppConstants.HTTP_OPTIONS)
    }

    addBusiness(dto: any) {
        return this.http.post(this.url, dto, AppConstants.HTTP_OPTIONS);
    }

    getStravaActivities() {
        return this.http.get(this.url + '/strava', AppConstants.HTTP_OPTIONS)
    }

    isStrava() {
        return this.http.get(this.url + '/has-strava', AppConstants.HTTP_OPTIONS)
    }

    isAdmin() {
        return this.http.get(this.urlNotAdmin + '/is-admin', AppConstants.HTTP_OPTIONS)
    }

    findDt(search: string, paginator: any, sort: Sort) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        if (search != null && search.trim().length > 0) {
            options.params.q = search
        }
        options.params.pageIndex = paginator.pageIndex
        options.params.pageSize = paginator.pageSize
        if (sort.direction && sort.active) {
            options.params.sortDirection = sort.direction
            options.params.sortActive = sort.active
        }
        return this.http.get(this.url + '/dt', options)
    }

    findBusinessDt(search: string, paginator: any, sort: Sort) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        if (search != null && search.trim().length > 0) {
            options.params.q = search
        }
        options.params.pageIndex = paginator.pageIndex
        options.params.pageSize = paginator.pageSize
        if (sort.direction && sort.active) {
            options.params.sortDirection = sort.direction
            options.params.sortActive = sort.active
        }
        return this.http.get(this.url + '/business/dt', options)
    }

    findById(id: bigint) {
        return this.http.get(this.url + '/' + id, AppConstants.HTTP_OPTIONS)
    }

    setAdmin(id: bigint) {
        return this.http.put(this.url + '/' + id + '/admin', null, AppConstants.HTTP_OPTIONS)
    }

    setBusiness(id: bigint) {
        return this.http.put(this.url + '/' + id + '/business', null, AppConstants.HTTP_OPTIONS)
    }

    findAvailabilitiesById(id: bigint, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/' + id + '/availabilities', options)
    }

    findTrainingsById(id: bigint, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/' + id + '/trainings', options)
    }

    findObjectifsById(id: bigint, start: string, end: string) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/' + id + '/objectifs', options)
    }

    findNextObjectifsById(id: bigint, today: string) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.today = today
        return this.http.get(this.url + '/' + id + '/objectifs/next', options)
    }

    findObjectifsV2ById(id: bigint) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        return this.http.get(this.url + '/' + id + '/objectifs-v2', options)
    }

    enumsValues() {
        return this.http.get(this.public + '/enums', AppConstants.HTTP_OPTIONS)
    }

    addCoaching(id: bigint, startWeek: number, endWeek: number, dto: any) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.startWeek = startWeek
        options.params.endWeek = endWeek
        return this.http.post(this.url + '/' + id + '/coaching', dto, options)
    }

    updateCoaching(id: bigint, dto: any, start: number, end: number, startWeek: number, endWeek: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        options.params.startWeek = startWeek
        options.params.endWeek = endWeek
        return this.http.put(this.url + '/' + id + '/coaching', dto, options)
    }

    deleteCoaching(coachingId: bigint, start: number, end: number, startWeek: number, endWeek: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        options.params.startWeek = startWeek
        options.params.endWeek = endWeek
        return this.http.delete(this.url + '/coaching/' + coachingId, options)
    }

    findToTrainDt(currentWeek: boolean, onlyNew: boolean, search: string, paginator: any, sort: Sort) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        if (search != null && search.trim().length > 0) {
            options.params.q = search
        }
        options.params.pageIndex = paginator.pageIndex
        options.params.pageSize = paginator.pageSize
        options.params.currentWeek = currentWeek
        options.params.onlyNew = onlyNew
        if (sort.direction && sort.active) {
            options.params.sortDirection = sort.direction
            options.params.sortActive = sort.active
        }
        return this.http.get(this.url + '/to-train-dt', options)
    }

    validateTrainingsById(id: bigint, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.put(this.url + '/' + id + '/trainings/validate', null, options)
    }

    unvalidateTrainingsById(id: bigint, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.put(this.url + '/' + id + '/trainings/unvalidate', null, options)
    }

    getTime(userId: bigint, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/' + userId + '/time', options)
    }

    getWeather(id: bigint, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/' + id + '/weather', options)
    }

    getBiorythmes(id: bigint, start: string) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        return this.http.get(this.url + '/' + id + '/biorythmes', options)
    }

    getDashboard(start: number, end: number, todayStart: number, todayEnd: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        options.params.todayStart = todayStart
        options.params.todayEnd = todayEnd
        return this.http.get(this.url + '/dashboard', options)
    }

    save(dto: any) {
        return this.http.put(this.url, dto, AppConstants.HTTP_OPTIONS)
    }

    getStats(start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/stats', options)
    }

    getDashboardDt(start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/dashboard/dt', options)
    }

    getDashboardPremiumDt(start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        return this.http.get(this.url + '/dashboard/premium-dt', options)
    }

    downloadXlsx() {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.responseType = 'arraybuffer'
        return this.http.get(this.url + '/xlsx', options)
    }

    saveBusinessInfos(id: bigint, dto: any) {
        return this.http.post(this.url + '/' + id + '/business', dto, AppConstants.HTTP_OPTIONS)
    }


    saveBusinessDocument(id: bigint, fmData: FormData) {
        return this.http.post(this.url + '/' + id + '/business/document', fmData)
    }

    findBusinessDocuments(id: bigint) {
        return this.http.get(this.url + '/' + id + '/business/document', AppConstants.HTTP_OPTIONS)
    }

    deleteBusinessDocument(id: bigint, docId: bigint) {
        return this.http.delete(this.url + '/' + id + '/business/document/' + docId, AppConstants.HTTP_OPTIONS)
    }

    setBusinessDocumentVisible(id: bigint, docId: bigint, visible: boolean) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.visible = visible
        return this.http.put(this.url + '/' + id + '/business/document/' + docId, undefined, options)
    }

    saveCoachingMessage(id: bigint, dto: any) {
        return this.http.post(this.url + '/' + id + '/coaching/message', dto, AppConstants.HTTP_OPTIONS)
    }

    updateCoachingMessage(id: bigint, dto: any) {
        return this.http.put(this.url + '/' + id + '/coaching/message', dto, AppConstants.HTTP_OPTIONS)
    }

    findCoachingMessageDt(id: bigint) {
        return this.http.get(this.url + '/' + id + '/coaching/message', AppConstants.HTTP_OPTIONS)
    }

    deleteCoachingMessage(id: bigint, messageId: bigint) {
        return this.http.delete(this.url + '/' + id + '/coaching/message/' + messageId, AppConstants.HTTP_OPTIONS)
    }

    findDecisions(availability: any, timezone: string) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.timezone = timezone
        return this.http.post(this.url + '/decisions', availability, options)
    }

    findAvailabilityById(availabilityId: bigint) {
        return this.http.get(this.url + '/availability/' + availabilityId, AppConstants.HTTP_OPTIONS)
    }

    findCoachingById(trainingId: bigint) {
        return this.http.get(this.url + '/coaching/' + trainingId, AppConstants.HTTP_OPTIONS)
    }

    updateInternalComment(id: bigint, dto: any) {
        return this.http.put(this.url + '/' + id + '/biorythmes', dto, AppConstants.HTTP_OPTIONS)
    }

    findProbsDt(id: bigint, dto: any, start: number, end: number, search: string, grouped: boolean, paginator: any) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        if (search != null && search.trim().length > 0) {
            options.params.q = search
        }
        options.params.grouped = grouped
        options.params.pageIndex = paginator.pageIndex
        options.params.pageSize = paginator.pageSize
        options.params.start = start
        options.params.end = end
        return this.http.post(this.url + '/' + id + '/training-probs/dt', dto, options)
    }

    refreshUser() {
        this.refreshUserObservableSource.next(null)
    }

    refreshCalendar() {
        this.refreshCalendarObservableSource.next(null)
    }
}