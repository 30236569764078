/* eslint-disable */

export const calendars = [
    {
        id: '1a470c8e-40ed-4c2d-b590-a4f1f6ead6cc',
        title: 'Personal',
        color: 'bg-teal-500',
        visible: true
    },
    {
        id: '5dab5f7b-757a-4467-ace1-305fe07b11fe',
        title: 'Work',
        color: 'bg-indigo-500',
        visible: true
    },
    {
        id: '09887870-f85a-40eb-8171-1b13d7a7f529',
        title: 'Appointments',
        color: 'bg-pink-500',
        visible: true
    }
];
export const events = [
    // Personal

];
export const exceptions = [];
export const settings = {
    dateFormat: 'll', // Aug 20, 2019
    timeFormat: '24', // 24-hour format
    startWeekOn: 1 // Monday
};
export const weekdays = [
    {
        abbr: 'M',
        label: 'Monday',
        value: 'MO'
    },
    {
        abbr: 'T',
        label: 'Tuesday',
        value: 'TU'
    },
    {
        abbr: 'W',
        label: 'Wednesday',
        value: 'WE'
    },
    {
        abbr: 'T',
        label: 'Thursday',
        value: 'TH'
    },
    {
        abbr: 'F',
        label: 'Friday',
        value: 'FR'
    },
    {
        abbr: 'S',
        label: 'Saturday',
        value: 'SA'
    },
    {
        abbr: 'S',
        label: 'Sunday',
        value: 'SU'
    }
];
