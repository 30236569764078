import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstants } from './app.constants';

@Component({
	selector: 'confirm',
	templateUrl: 'confirm.dialog.html'
})
export class ConfirmDialogComponent implements OnInit {
	content: any;

	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data
	) { }

	ngOnInit() {
		this.content = this.data;
	}

	close() {
		this.dialogRef.close(AppConstants.SUCCESS_DIALOG);
	}

	cancel() {
		this.dialogRef.close();
	}
}
