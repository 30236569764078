import { HttpHeaders } from "@angular/common/http";
import { DateTime } from "luxon";

export class AppConstants {
    public static HTTP_OPTIONS = <any>{
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        }),
        params: { '_v': new Date().getTime().toString() }
    };

    public static SOCKET_URL = '/socket.io'
    public static PUBLIC_URL = '/api/public/';
    public static PRIVATE_URL = '/api/rest/';
    public static ALTERNATEDOCROOTS = '/alternatedocroots/';
    public static ERROR = 'Une erreur est survenue';
    public static SUCCESS_DIALOG = 'SUCCESS'
    public static SLOT_DURATION = 30
    public static SLOT_MIN = '00:00:00'
    public static SLOT_MAX = '24:00:00'

    public static EXCEPTION = {
        ALREADY_EXIST_CODE: 'ALREADY_EXIST_CODE',
        ALREADY_EXIST_USER: 'ALREADY_EXIST_USER'
    }

    public static calculAge(birthyear: number): number {
        if (birthyear) {
            return DateTime.now().year - birthyear
        }
        return null
    }

    public static DIALOG_CONFIG = <any>{
        SMALL: {
            height: 'auto',
            maxHeight: '80%',
            width: '450px'
        },
        AVERAGE: {
            height: 'auto',
            maxHeight: '80%',
            width: '500px'
        },
        BIG: {
            height: 'auto',
            maxHeight: '80%',
            width: '800px'
        },
        LARGE: {
            height: 'auto',
            maxHeight: '80%',
            width: '80%'
        }
    };
    public static MAP_USER_ENUM = {
        cyclistType: {
            'SPRINTEUR': 'Sprinteur',
            'SPRINTEUR_ROULEUR': 'Sprinteur - Rouleur',
            'ROULEUR': 'Rouleur',
            'ROULEUR_GRIMPEUR': 'Rouleur - Grimpeur',
            'GRIMPEUR': 'Grimpeur',
            'PUNCHEUR': 'Puncheur'
        },
        workingType: {
            'DECONTRACTION': 'Décontraction',
            'DIET_TRAINING': 'Diet training',
            'FONCIER': 'Foncier',
            'VELOCITE': 'Vélocité',
            'RENFORT_VELO': 'Renfo vélo',
            'MUSCU_VELO': 'Muscu vélo',
            'RYTHME': 'Rythme',
            'PMA': 'Zone Rouge',
            'RESISTANCE': 'Résistance',
            'FORCE': 'Force',
            'EXPLOSIVITE': 'Explosivité',
            'SIMULATION': 'Simulation',
            'DEBLOCAGE': 'Déblocage',
            'MUSCULATION': 'Musculation',
            'PYRAMIDALE': 'Pyramidale',
            'REPOS': 'Repos',
            'TEST_STRAVA': 'Test Sorius',
            'STAGE': 'Stage'
        },
        discipline: {
            '': 'Au choix par Sorius',
            'CAP': 'Course à pied',
            'CAP_PLAT': 'Course à pied sur le plat',
            'CAP_DENI': 'Course à pied sur le dénivelé',
            'CLM': 'Contre la montre',
            'CYCLO_CROSS': 'Cyclo-cross',
            'GRAVEL': 'Gravel',
            'HOME_TRAINER': 'Home Trainer',
            'HOME_TRAINER_CLASSIQUE': 'Home Trainer classique',
            'HOME_TRAINER_CONNECTE': 'Home Trainer connecté',
            'MARCHE': 'Marche',
            'NATATION': 'Natation',
            'PISTE': 'Piste',
            'PISTE_INTERIEURE': 'Piste intérieure',
            'PISTE_EXTERIEURE': 'Piste extérieure',
            'PPG': 'PPG',
            'ROUTE': 'Route',
            'SKI_DE_FOND': 'Ski de fond',
            'SKI_DE_RANDO': 'Ski de randonnée',
            'SORTIE_CLUB': 'Sortie en groupe',
            'STAGE_ORGANISE': 'Stage organisé',
            'RENFORCEMENT_MUSCULAIRE': 'Renforcement musculaire',
            'VAE': 'VAE',
            'VAER': 'VAE Route',
            'VELOTAF': 'Vélotaf',
            'VTT': 'VTT',
            'VTTAE': 'VTTAE',
            'BMX': 'BMX',
            'CC': 'Compétition connectée',
            'PF': 'Pignon fixe'
        },
        level: {
            'DEBUTANT': 'Débutant',
            'PRATIQUANT_OCCASIONNEL': 'Pratiquant occasionnel',
            'PRATIQUANT_SPORTIF': 'Pratiquant cyclo-sportif',
            'AMATEUR_REGIONAL': 'Amateur régional',
            'AMATEUR_NATIONAL': 'Amateur national',
            'PRO': 'Professionnel',
        },
        category: {
            'INSIDE': 'Intérieur',
            'OUTSIDE': 'Extérieur',
            'INSIDE_OUTSIDE': 'Intérieur/Extérieur'
        },
        objectifCategory: {
            'ELITE': 'Elite',
            'FIRST_CAT': '1ère catégorie',
            'SECOND_CAT': '2ème catégorie',
            'THIRD_CAT': '3ème catégorie',
            'DEPARTEMENTAL': 'Départemental',
            'CYCLO_COMPET': 'Cyclo-sportive compétition',
            'CYCLO_LOISIR': 'Cyclo-sportive loisir',
            'VIRTUAL': 'Compétition virtuelle',
            'ESPOIR': 'Espoir',
            'JUNIOR': 'Junior',
            'MASTER': 'Master',
            'PRO': 'Professionnel',
            'SENIOR': 'Senior',
            'CADET': 'Cadet'
        },
        civility: {
            OTHER: 'Autre',
            M: 'M.',
            MME: 'Mme.',
        },
        departEffort: {
            ARRETE: 'Départ arrêté',
            LANCE: 'Départ lancé',
            SURVELOCITE: 'En survélocité'
        },
        exercicesTest: {
            ROULEUR: 'Échauffement: 1h entre 70 et 80% de ta FCmax + déblocage de 3 sprints de 100m à bloc avec 1km de récupération entre les efforts + Récupère 15\' petit plateau.\nTEST Chronométré rouleur: sur un segment de 250m lancé en produisant toute ta puissance.',
            SPRINTEUR: 'Échauffement:1h entre 70 et 80% de ta FCmax + déblocage de 2 x 1km entre 90% et 95% de ta FCmax avec une récupération de 3\' entre les efforts + 10\' entre 60 et 70% de ta FCmax.\nTEST Chronométré sprinteur: sur le segment de 5km à 10km à bloc.',
            GRIMPEUR: 'Échauffement: 1h entre 70 et 80% de ta FCmax + déblocage de 2 x 1km entre 90 et 95% de ta FCmax avec une récupération de 3\' entre les efforts + 15 entre 60 et 70% de ta FCmax.\nTEST Chronométré grimpeur:sur un segment entre 2km et 5km à bloc sur un dénivelé positif de 5% à 8% de moyenne.',
            PUNCHEUR: 'Échauffement: 1h entre 70 et 80% de ta FCmax + déblocage de 2 x 200m entre 90% et 95% de ta FCmax avec une récupération de 3\' entre les efforts + 10\' entre 60 et 70% de ta FCmax.\nTEST Chronométré puncheur:5 x 400m à bloc en dénivelé positif avec une récupération de 4\' entre les efforts. Le résultat du test étant l\'addition des 5 efforts chronométrés.'
        },
        types: {
            'PERSO': 'Séance personnelle',
            'GROUPE': 'Sortie en groupe',
            'STAGE_PERSO': 'Stage personnel',
            'STAGE_ORGA': 'Stage organisé'
        },
        trackingTypes: {
            NO_PROMO: 'Sans promo',
            ONE_MONTH: '1 mois de période d\'essai en plus',
            TWO_MONTHS: '2 mois de période d\'essai en plus',
            THREE_MONTHS: '3 mois de période d\'essai en plus',
            SIX_MONTHS: '6 mois de période d\'essai en plus',
        }

    }
}