import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AppConstants } from 'app/app.constants';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class UserBusinessService {

    private url = AppConstants.PRIVATE_URL + 'business/user'

    constructor(
        private http: HttpClient
    ) { }


    isBusiness() {
        return this.http.get(this.url + '/is-business', AppConstants.HTTP_OPTIONS)
    }

    findDt(search: string, paginator: any, sort: Sort) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        if (search != null && search.trim().length > 0) {
            options.params.q = search
        }
        options.params.pageIndex = paginator.pageIndex
        options.params.pageSize = paginator.pageSize
        if (sort.direction && sort.active) {
            options.params.sortDirection = sort.direction
            options.params.sortActive = sort.active
        }
        return this.http.get(this.url + '/dt', options)
    }

    getInfos() {
        return this.http.get(this.url, AppConstants.HTTP_OPTIONS)
    }

    save(dto: any) {
        return this.http.post(this.url, dto, AppConstants.HTTP_OPTIONS)
    }

    generateRandomCode() {
        return this.http.get(this.url + '/code', AppConstants.HTTP_OPTIONS)
    }

    listCodes() {
        return this.http.get(this.url + '/codes', AppConstants.HTTP_OPTIONS)
    }

    getDashboardDt(codeId: bigint | undefined, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        if (codeId != null) {
            options.params.codeId = codeId
        }
        return this.http.get(this.url + '/dashboard/dt', options)
    }

    getDashboardPremiumDt(codeId: bigint | undefined, start: number, end: number) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.params.start = start
        options.params.end = end
        if (codeId != null) {
            options.params.codeId = codeId
        }
        return this.http.get(this.url + '/dashboard/premium-dt', options)
    }

    getFrontUrl() {
        return this.http.get(this.url + '/front-url', AppConstants.HTTP_OPTIONS)
    }

    getLandingUrl() {
        return this.http.get(this.url + '/landing-url', AppConstants.HTTP_OPTIONS)
    }

    getVisibleDocuments() {
        return this.http.get(this.url + '/documents', AppConstants.HTTP_OPTIONS)
    }

    downloadDocument(documentId: bigint) {
        const options = _.cloneDeep(AppConstants.HTTP_OPTIONS)
        options.responseType = 'blob' as 'json'
        options.observe = 'response'
        return this.http.get<Blob>(this.url + '/document/' + documentId, options)
    }

}