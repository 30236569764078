import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import * as _ from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Get admin navigation data
     */
    getAdmin(): Observable<Navigation> {
        const items: FuseNavigationItem[] = [
            {
                id: 'dashboard',
                title: 'Tableau de bord',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: '/dashboard'
            },
            {
                id: 'stats',
                title: 'Statistiques',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: '/stats'
            },
            {
                id: 'users',
                title: 'Gestion des utilisateurs',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: '/users'
            },
            {
                id: 'coaching',
                title: 'Coaching à préparer',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: '/coaching'
            },
            {
                id: 'challenges',
                title: 'Challenges',
                type: 'basic',
                icon: 'mat_outline:directions_bike',
                link: '/challenges'
            },
        ];
        const nav: Navigation = {
            compact: _.cloneDeep(items),
            default: _.cloneDeep(items),
            futuristic: _.cloneDeep(items),
            horizontal: _.cloneDeep(items)
        };
        this._navigation.next(nav);
        return of(nav);
    }

    getBusiness(): Observable<Navigation> {
        const items: FuseNavigationItem[] = [
            {
                id: 'dashboard',
                title: 'Tableau de bord',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: '/home'
            },
            {
                id: 'tracking',
                title: 'Codes de tracking',
                type: 'basic',
                icon: 'heroicons_outline:location-marker',
                link: '/tracking'
            },
            {
                id: 'documents',
                title: 'Documents',
                type: 'basic',
                icon: 'heroicons_outline:folder',
                link: '/documents'
            }
        ];
        const nav: Navigation = {
            compact: _.cloneDeep(items),
            default: _.cloneDeep(items),
            futuristic: _.cloneDeep(items),
            horizontal: _.cloneDeep(items)
        };
        this._navigation.next(nav);
        return of(nav);
    }
}
