<div class="dialog-content-wrapper">
	<div mat-dialog-title class="mat-accent m-0">
		<div class="flex flex-row items-center">
			<span class="flex-auto">{{content.title}}</span>
			<button mat-button class="mat-icon-button flex-none" mat-dialog-close aria-label="Close dialog">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
	<mat-dialog-content fusePerfectScrollbar>{{content.message}}</mat-dialog-content>
	<mat-dialog-actions class="flex flex-row">
		<button mat-raised-button *ngIf="!content.justOk" color="accent" (click)="close()">Valider</button>
		<button mat-button *ngIf="!content.justOk" color="basic" (click)="cancel()">Annuler</button>
		<button mat-raised-button color="basic" *ngIf="content.justOk" (click)="close()">Ok</button>
	</mat-dialog-actions>
</div>