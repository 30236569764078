/* eslint-disable */

export const labels = [
    {
        id: 'f47c92e5-20b9-44d9-917f-9ff4ad25dfd0',
        title: 'Family'
    },
    {
        id: 'e2f749f5-41ed-49d0-a92a-1c83d879e371',
        title: 'Work'
    },
    {
        id: 'b1cde9ee-e54d-4142-ad8b-cf55dafc9528',
        title: 'Tasks'
    },
    {
        id: '6c288794-47eb-4605-8bdf-785b61a449d3',
        title: 'Priority'
    },
    {
        id: 'bbc73458-940b-421c-8d5f-8dcd23a9b0d6',
        title: 'Personal'
    },
    {
        id: '2dc11344-3507-48e0-83d6-1c047107f052',
        title: 'Friends'
    }
];

export const notes = [

];
