import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { calendars as calendarsData, events as eventsData, exceptions as exceptionsData, settings as settingsData, weekdays as weekdaysData } from 'app/mock-api/apps/calendar/data';
import { assign, cloneDeep } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class CalendarMockApi {
    private _calendars: any[] = calendarsData;
    private _events: any[] = eventsData;
    private _exceptions: any[] = exceptionsData;
    private _settings: any = settingsData;
    private _weekdays: any = weekdaysData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Calendars - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/calendar/calendars')
            .reply(() => {

                // Clone the calendars
                const calendars = cloneDeep(this._calendars);

                // Return the response
                return [200, calendars];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Calendars - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/calendar/calendars')
            .reply(({ request }) => {

                // Get the calendar as the new calendar
                const newCalendar = cloneDeep(request.body.calendar);

                // Add an id to the new calendar
                newCalendar.id = FuseMockApiUtils.guid();

                // Push the new calendar
                this._calendars.push(newCalendar);

                // Return the response
                return [200, newCalendar];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Calendars - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/calendar/calendars')
            .reply(({ request }) => {

                // Get the id and calendar
                const id = request.body.id;
                const calendar = cloneDeep(request.body.calendar);

                // Prepare the updated calendar
                let updatedCalendar = null;

                // Find the calendar and update it
                this._calendars.forEach((item, index, calendars) => {

                    if (item.id === id) {
                        // Update the calendar
                        calendars[index] = assign({}, calendars[index], calendar);

                        // Store the updated calendar
                        updatedCalendar = calendars[index];
                    }
                });

                // Return the response
                return [200, updatedCalendar];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Calendars - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/calendar/calendars')
            .reply(({ request }) => {

                // Get the id
                const id = request.params.get('id');

                // Find the calendar and delete it
                const index = this._calendars.findIndex(calendar => calendar.id === id);
                this._calendars.splice(index, 1);

                // Find the events that belong to the calendar and remove them as well
                this._events = this._events.filter(event => event.calendarId !== id);

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Events - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/calendar/events')
            .reply(({ request }) => {

                // Get the start and end dates as moment


                // Return the response
                return [200, []];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Event - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/apps/calendar/event')
            .reply(({ request }) => {

                // Get the event as the new event
                const newEvent = cloneDeep(request.body.event);

                // Add an id to the new event
                newEvent.id = FuseMockApiUtils.guid();

                // Unshift the new event
                this._events.unshift(newEvent);

                // Return the response
                return [200, newEvent];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Event - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/calendar/event')
            .reply(({ request }) => {

                // Get the id and event
                const id = request.body.id;
                const event = cloneDeep(request.body.event);

                // Prepare the updated event
                let updatedEvent = null;

                // Find the event and update it
                this._events.forEach((item, index, events) => {

                    if (item.id === id) {
                        // Update the event
                        events[index] = assign({}, events[index], event);

                        // Store the updated event
                        updatedEvent = events[index];
                    }
                });

                // Return the response
                return [200, updatedEvent];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Event - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/calendar/event')
            .reply(({ request }) => {

                // Get the id and event
                const id = request.params.get('id');

                // Find the event and delete it
                const index = this._events.findIndex(item => item.id === id);
                this._events.splice(index, 1);

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Recurring Event - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/apps/calendar/recurring-event')
            .reply(({ request }) => {



                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Recurring Event - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/apps/calendar/recurring-event')
            .reply(({ request }) => {


                // Return the response
                return [200, {}];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Weekdays - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/calendar/weekdays')
            .reply(() => {

                // Clone the weekdays
                const weekdays = cloneDeep(this._weekdays);

                // If the startWeekOn setting is set to Sunday...
                if (this._settings.startWeekOn === 0) {
                    // Move the Sunday to the beginning
                    weekdays.unshift(weekdays.pop());
                }

                // If the startWeekOn is set to Saturday...
                if (this._settings.startWeekOn === 6) {
                    // Move the Sunday to the beginning
                    weekdays.unshift(weekdays.pop());

                    // Then move the Saturday to the beginning
                    weekdays.unshift(weekdays.pop());
                }

                // Return the response
                return [200, weekdays];
            });
    }

}
